import { Disclosure, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import type { ReactNode } from 'react';
import React from 'react';

import LinkButton from '../atoms/LinkButton';

type ExpandableProps = {
  title: string;
  children?: ReactNode;
  valueSelected?: string;
  handleClear?: () => void;
};

const Expandable = ({
  title,
  children,
  valueSelected = '',
  handleClear,
}: ExpandableProps) => {
  const isHasValue = valueSelected.length > 0;
  const buttonCls = isHasValue ? 'border-slate-900' : 'border-transparent';

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <div className="mx-5">
            <Disclosure.Button
              className={`mb-5 flex w-full flex-row items-center justify-between rounded-md border bg-white px-5 py-3 ${buttonCls}`}
            >
              <h5 className="mr-5 text-left text-base text-gray-600">
                {title}
              </h5>
              <h6 className="mr-3 flex-1 truncate text-right text-base">
                {valueSelected}
              </h6>
              {!open && <ChevronDownIcon className="w-5 text-slate-400" />}
              {open && <ChevronUpIcon className="w-5 text-slate-400" />}
            </Disclosure.Button>
          </div>

          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className="border-b" static>
              {children}
              <div className="p-5">
                {isHasValue && (
                  <LinkButton onClick={handleClear} title="Clear Selected" />
                )}
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default Expandable;
