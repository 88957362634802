import { RadioGroup } from '@headlessui/react';
import React from 'react';

import type ButtonGroupItemProps from '@/types/ButtonGroupItem.type';

interface ButtonGroupProps {
  value: string;
  buttons: ButtonGroupItemProps[];
  onChange: (value: string) => void;
}

const ButtonGroup = ({ value, buttons, onChange }: ButtonGroupProps) => {
  return (
    <RadioGroup
      value={value}
      onChange={onChange}
      className="mt-2 flex flex-row overflow-hidden rounded-lg md:mt-0"
    >
      {buttons.map((button) => (
        <RadioGroup.Option key={button.value} value={button.value} as="button">
          {({ checked }) => (
            <div
              className={`flex items-center justify-center py-2 pr-5 text-sm uppercase md:pr-0 md:pl-5  ${
                checked ? 'font-bold text-slate-900' : 'text-gray-500'
              }`}
            >
              {button.title}
            </div>
          )}
        </RadioGroup.Option>
      ))}
    </RadioGroup>
  );
};

export default ButtonGroup;
