import { Popover, Transition } from '@headlessui/react';
import type { ReactNode } from 'react';
import React from 'react';

type CustomPopoverProps = {
  title: string;
  placeholder: string;
  value?: string;
  isFilterExpended: boolean;
  children: ReactNode;
  onClear?: React.MouseEventHandler<HTMLButtonElement>;
};

const CustomPopover = ({
  title,
  placeholder,
  value,
  isFilterExpended = false,
  children,
  onClear,
}: CustomPopoverProps) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`flex w-full flex-col ${
              isFilterExpended ? 'items-start' : 'items-center'
            } justify-center outline-none hover:cursor-pointer`}
            disabled={!isFilterExpended}
          >
            <h5
              className={`${
                !isFilterExpended && 'text-center'
              } whitespace-nowrap`}
            >
              {title}
            </h5>
            <Transition
              appear
              show={isFilterExpended}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <h6
                className={`${
                  value && value.length > 0 ? 'font-medium' : 'font-thin'
                } text-left line-clamp-1`}
              >
                {value && value.length > 0 ? value : placeholder}
              </h6>
            </Transition>
          </Popover.Button>

          <Transition
            show={open}
            enter="transition duration-200 ease-out"
            enterFrom="transform scale-50 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-50 opacity-0"
          >
            <Popover.Panel className="absolute z-10 mt-4  w-screen max-w-sm  overflow-hidden rounded-xl bg-white shadow-md">
              <div className="max-h-96  overflow-y-auto">{children}</div>
              {onClear && value && value?.length > 0 && (
                <button
                  onClick={onClear}
                  className="flex w-full items-center justify-center bg-slate-200 py-3 px-5 hover:bg-slate-300"
                >
                  <span className="text-sm uppercase">Clear Selected</span>
                </button>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default CustomPopover;
