import React from 'react';

import useFilterStore from '@/store/FilterStore';
import type CommonProps from '@/types/Common.type';

import RegionCard from '../atoms/RegionCard';
import CustomPopover from '../popover/CustomPopover';

type RegionsSelectProps = {
  isFilterExpended: boolean;
  data: CommonProps[];
};

const RegionsSelect = ({
  isFilterExpended,
  data: regions,
}: RegionsSelectProps) => {
  const { regionsSelected, setRegions, clearRegion } = useFilterStore(
    (state) => state
  );
  const getValue = () => regionsSelected.map((item) => item.name).join(', ');

  return (
    <div className={`${isFilterExpended ? 'w-28' : 'w-16'} flex-none text-sm`}>
      <CustomPopover
        title="Regions"
        placeholder="Rest of the world"
        isFilterExpended={isFilterExpended}
        value={getValue()}
      >
        <div className="p-5">
          <div className="flex w-full flex-row items-start justify-between">
            <h4 className="mb-4 flex-1">Search by regions</h4>
            <button
              onClick={clearRegion}
              className="flex items-center text-slate-600 underline hover:text-slate-700"
            >
              <span className="text-sm">Clear</span>
            </button>
          </div>
          <div className="grid grid-cols-3 gap-5">
            {regions.map((item) => (
              <RegionCard
                key={item.id}
                region={item}
                selected={regionsSelected}
                handleSelection={() => setRegions(item)}
              />
            ))}
          </div>
        </div>
      </CustomPopover>
    </div>
  );
};

export default RegionsSelect;
