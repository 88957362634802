import Head from 'next/head';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';

import { AppConfig } from '@/utils/AppConfig';
import { APP_ENV } from '@/utils/const';

type IMetaProps = {
  title: string;
  description: string;
  canonical?: string;
};

const Meta = (props: IMetaProps) => {
  const router = useRouter();

  return (
    <>
      <Head>
        <meta charSet="UTF-8" key="charset" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1"
          key="viewport"
        />
        <link
          rel="apple-touch-icon"
          href={`${router.basePath}/icons/apple-icon.png`}
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/icons/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/icons/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/icons/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/icons/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/icons/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/icons/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/icons/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/icons/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/icons/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/icons/android-icon-192x192.png"
        />

        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${router.basePath}/favicon-32x32.png`}
          key="icon32"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${router.basePath}/favicon-16x16.png`}
          key="icon16"
        />
        <link
          rel="icon"
          href={`${router.basePath}/favicon.ico`}
          key="favicon"
        />
        <link rel="manifest" href={`${router.basePath}/manifest.json`} />
        <meta name="theme-color" content="#fff" />
        <meta
          name="msapplication-config"
          content={`${router.basePath}/icons/browserconfig.xml`}
        />
        <meta name="msapplication-TileColor" content="#182331" />
        <meta name="msapplication-tap-highlight" content="no" />
      </Head>

      <NextSeo
        title={props.title}
        description={props.description}
        canonical={props.canonical}
        openGraph={{
          title: props.title,
          description: props.description,
          url: props.canonical,
          locale: AppConfig.locale,
          site_name: AppConfig.site_name,
          images: [
            {
              url: `${router.basePath}/vb-map-social.jpg`,
              width: 1200,
              height: 630,
              alt: 'Og Image Alt',
              type: 'image/jpeg',
            },
            {
              url: `${router.basePath}/vb-map-social-sm.jpg`,
              width: 476,
              height: 250,
              alt: 'Og Image Alt Second',
              type: 'image/jpeg',
            },
          ],
        }}
        twitter={{
          handle: '@slashco',
          site: '@slashco',
          cardType: 'summary_large_image',
        }}
        nofollow={process.env.NEXT_PUBLIC_DEBUG_MODE === APP_ENV.DEV}
        noindex={process.env.NEXT_PUBLIC_DEBUG_MODE === APP_ENV.DEV}
      />
    </>
  );
};

export { Meta };
