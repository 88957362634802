import { Dialog, Transition } from '@headlessui/react';
import type { ReactNode } from 'react';
import React, { Fragment } from 'react';

import CloseModalButton from '../atoms/CloseModalButton';

type PopupProps = {
  isVisible: boolean;
  handleClose: () => void;
  isFullScreen?: boolean;
  children: ReactNode;
  isShowClose?: boolean;
};

const SimplePopup = ({
  isVisible,
  handleClose,
  children,
  isFullScreen = false,
  isShowClose = true,
}: PopupProps) => {
  const containerCls = isFullScreen
    ? 'h-screen w-screen'
    : 'min-h-full py-4 px-6 lg:px-4';

  const panelCls = isFullScreen ? 'rounded-none bg-slate-50' : 'max-w-3xl';

  return (
    <Transition appear show={isVisible} as={Fragment}>
      <Dialog onClose={handleClose} className="relative z-50">
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        </Transition.Child>

        {/* Full-screen scrollable container */}
        <div className="fixed inset-0 overflow-y-auto">
          {/* Container to center the panel */}
          <div className={`flex items-center justify-center ${containerCls}`}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {/* The actual dialog panel  */}
              <Dialog.Panel
                className={`modelContainer-content relative h-full w-full ${panelCls}`}
              >
                <div className="m-auto h-full max-w-3xl">
                  {isShowClose && (
                    <div className="absolute top-0 right-0 ">
                      <CloseModalButton handleClick={handleClose} />
                    </div>
                  )}

                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SimplePopup;
