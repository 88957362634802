enum TagType {
  Success,
  Warning,
  Error,
  Info,
  Default,
}

enum OperationStatus {
  ACTIVE = 'active',
  REVIEW = 'review',
  INACTIVE = 'inactive',
}

enum AnalyticEvent {
  Subscription = 'newsletter-subscription',
  Drawer = 'drawer-menu-mobile',
  DownloadEbook = 'download-ebook',
  Podcasts = 'podcasts',
  RequestNewCompany = 'request-new-company',
  HideShowCompanyList = 'hide-show-company-list',
  ViewByListing = 'view-by-listing',
  ViewByMapPopup = 'view-by-map-popup',
  ViewByRelevant = 'view-by-relevant',
  ViewCompanyDetail = 'view-company-detail',
  SocialPage = 'social-page',
  ViewCompanyWebsite = 'view-company-website',
  ViewCompanyCareerPage = 'view-company-career-page',
  ContactCompany = 'contact-to-company',
  BasicFilter = 'basic-filter',
  AdvanceFilter = 'advance-filter',
  LogoClick = 'vb-map-logo-click',
  ViewCompanyVentureWebsite = 'visit-company-venture-website',
  SubmitFilter = 'submit-filter',
  PaginationChange = 'company-list-pagination-change',
  FoundedYearFilterType = 'founded-year-filter-type', // specific or range selection
  ResetAllFilter = 'reset-all-filter',
  MapClusterClick = 'map-cluster-click',
  MapPinClick = 'map-pin-click',
  MapZoomInteraction = 'map-zoom-interaction',
  MapMoveInteraction = 'map-move-interaction',
}

export { AnalyticEvent, OperationStatus, TagType };
