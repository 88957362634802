const en = require('./en-US.json');

const i18n = {
  translations: {
    en,
  },
  defaultLang: 'en',
  useBrowserDefault: true,
};

module.exports = i18n;
