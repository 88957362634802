import React from 'react';

import useFilterStore from '@/store/FilterStore';
import type CommonProps from '@/types/Common.type';

import Checkbox from '../atoms/Checkbox';
import CustomPopover from '../popover/CustomPopover';

type IndustrySelectProps = {
  isFilterExpended: boolean;
  data: CommonProps[];
};

const IndustrySelect = ({
  isFilterExpended,
  data: industries,
}: IndustrySelectProps) => {
  const { industriesSelected, setIndustry, clearIndustry } = useFilterStore(
    (state) => state
  );

  const getValue = () => industriesSelected.map((item) => item.name).join(', ');

  return (
    <div className={`${isFilterExpended ? 'w-28' : 'w-16'} flex-none text-sm`}>
      <CustomPopover
        title="Industry"
        placeholder="Any industry"
        isFilterExpended={isFilterExpended}
        value={getValue()}
        onClear={clearIndustry}
      >
        <div className="py-2">
          <div className="grid grid-cols-1 gap-0">
            {industries.map((item) => (
              <Checkbox
                key={item.id}
                currentItem={item}
                selectedItems={industriesSelected}
                handleSelection={() => setIndustry(item)}
              />
            ))}
          </div>
        </div>
      </CustomPopover>
    </div>
  );
};

export default IndustrySelect;
