import { classes } from '@/utils/classes';

interface ButtonProps {
  title: string;
  size?: 'sm' | 'md' | 'lg';
  onClick: () => void;
}

const sizes = {
  sm: 'py-1.5 px-4 text-xs',
  md: 'py-3 px-6 text-sm',
  lg: 'py-4 px-8 text-base',
};

export default function Button({ title, size = 'md', onClick }: ButtonProps) {
  return (
    <button
      className={classes(
        'flex items-center justify-center rounded-full bg-slate-900 uppercase font-medium text-white hover:bg-slate-800',
        sizes[size]
      )}
      onClick={onClick}
    >
      {title}
    </button>
  );
}
