import React from 'react';

interface LinkButtonProps {
  title: string;
  onClick?: () => void;
}

const LinkButton = ({ title, onClick }: LinkButtonProps) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center text-slate-600 underline hover:text-slate-700"
    >
      <span className="text-sm uppercase">{title}</span>
    </button>
  );
};

export default LinkButton;
