import Image from 'next/image';
import React from 'react';

import type CommonProps from '@/types/Common.type';

type RegionCardProps = {
  region: CommonProps;
  selected: CommonProps[];
  handleSelection: () => void;
};

const RegionCard = ({ region, selected, handleSelection }: RegionCardProps) => {
  let isSelectedCls = 'border-transparent';
  selected.forEach((selectData) => {
    if (selectData?.id === region.id) {
      isSelectedCls = 'border-slate-900';
    }
  });

  return (
    <div
      className="self-start text-center hover:cursor-pointer"
      onClick={handleSelection}
    >
      <div
        className={`image-vertical-middle rounded-xl border-2 ${isSelectedCls}`}
      >
        <Image
          src={
            `/regions/${region.slug}.png` ||
            'https://a0.muscache.com/im/pictures/7b5cf816-6c16-49f8-99e5-cbc4adfd97e2.jpg?im_w=320'
          }
          width={100}
          height={100}
          alt=""
          className="rounded-xl"
        />
      </div>
      <h6 className="mt-1 text-center">{region.name}</h6>
    </div>
  );
};

export default RegionCard;
