import Image from 'next/image';
import { useCallback, useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Newsletter from '@/assets/newsletter.svg';
import NewsletterSubscriptionForm from '@/components/molecules/NewsletterSubscriptionForm';
import NewsletterSubscriptionSuccess from '@/components/molecules/NewsletterSubscriptionSuccess';
import useModalStore from '@/store/NavbarStore';
import useNewsletterStore from '@/store/NewsletterStore';

import SimplePopup from '../templates/Popup';

const NewsletterModal = () => {
  const { message, loading, reset } = useNewsletterStore((state) => state);
  const { isSubscriptionVisible, toggleSubscription } = useModalStore(
    (state) => state
  );

  useEffect(() => {
    return reset();
  }, []);

  const handleClose = useCallback(async () => {
    toggleSubscription(false);
    reset();
  }, []);

  return (
    <SimplePopup isVisible={isSubscriptionVisible} handleClose={handleClose}>
      <div className="flex flex-row">
        <div className="hidden w-72 flex-none justify-end bg-slate-500 px-6 pt-8 md:flex">
          <div className="translate-y-2 self-end">
            <Image src={Newsletter} alt="Newsletter Icon" quality={100} />
          </div>
        </div>
        {!loading && message.success ? (
          <NewsletterSubscriptionSuccess handleClose={handleClose} />
        ) : (
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.NEXT_PUBLIC_RE_CAPTCHA_SITE_KEY ?? ''}
            scriptProps={{
              async: false,
              defer: false,
              appendTo: 'head',
              nonce: undefined,
            }}
          >
            <NewsletterSubscriptionForm />
          </GoogleReCaptchaProvider>
        )}
      </div>
    </SimplePopup>
  );
};

export default NewsletterModal;
