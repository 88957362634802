import create from 'zustand';

import type CommonProps from '@/types/Common.type';
import type { CompanyFilterType } from '@/types/Filter.type';
import type { FilterData, FilterState } from '@/types/FilterStore.type';
import { getFilterStatusBool } from '@/utils/helper';

const handleSelection = (prev: CommonProps[], selectedItem: CommonProps) => {
  const isExisted = prev.findIndex((item) => item.id === selectedItem.id) > -1;

  if (isExisted) {
    const deSelected = prev.filter((item) => item.id !== selectedItem.id);

    return deSelected;
  }
  return [...prev, selectedItem];
};

const useFilterStore = create<FilterState>((set, get) => ({
  isVisible: false,
  searchText: '',
  regionsSelected: [],
  businessModelsSelected: [],
  industriesSelected: [],
  partnershipOpportunitiesSelected: [],
  activelyHiringsSelected: [],
  serviceOfferedsSelected: [],
  segmentsSelected: [],
  status: undefined,
  fundraisingStatus: undefined,
  verifyStatus: undefined,
  founded: undefined,
  filterBy: {
    search: '',
    regions: '',
    businessModels: '',
    industries: '',
    partnershipOpportunities: '',
    activelyHiring: '',
    segments: '',
    serviceOffereds: '',
  },
  filterData: undefined,
  currentPage: 0,
  updateSearchText: (text: string) => {
    set(() => ({
      searchText: text,
    }));
  },
  toggleFilter: (visible: boolean) => {
    set(() => ({
      isVisible: visible,
    }));
  },
  setRegions: (region: CommonProps) => {
    const prevSelected = get().regionsSelected;
    const commonUpdated = handleSelection(prevSelected, region);
    set(() => ({
      regionsSelected: commonUpdated,
    }));
  },
  setBusinessModel: (selectedItem: CommonProps) => {
    const prevSelected = get().businessModelsSelected;
    const commonUpdated = handleSelection(prevSelected, selectedItem);
    set(() => ({
      businessModelsSelected: commonUpdated,
    }));
  },
  setIndustry: (selectedItem: CommonProps) => {
    const prevSelected = get().industriesSelected;
    const commonUpdated = handleSelection(prevSelected, selectedItem);
    set(() => ({
      industriesSelected: commonUpdated,
    }));
  },
  setPartnershipOpportunities: (selectedItem: CommonProps) => {
    const prevSelected = get().partnershipOpportunitiesSelected;
    const commonUpdated = handleSelection(prevSelected, selectedItem);
    set(() => ({
      partnershipOpportunitiesSelected: commonUpdated,
    }));
  },
  setActivelyHirings: (selectedItem: CommonProps) => {
    const prevSelected = get().activelyHiringsSelected;
    const commonUpdated = handleSelection(prevSelected, selectedItem);
    set(() => ({
      activelyHiringsSelected: commonUpdated,
    }));
  },
  setSegments: (selectedItem: CommonProps) => {
    const prevSelected = get().segmentsSelected;
    const commonUpdated = handleSelection(prevSelected, selectedItem);
    set(() => ({
      segmentsSelected: commonUpdated,
    }));
  },
  setServiceOffereds: (selectedItem: CommonProps) => {
    const prevSelected = get().serviceOfferedsSelected;
    const commonUpdated = handleSelection(prevSelected, selectedItem);
    set(() => ({
      serviceOfferedsSelected: commonUpdated,
    }));
  },
  submitFilter: () => {
    const {
      searchText,
      founded,
      status,
      fundraisingStatus,
      verifyStatus,
      regionsSelected,
      businessModelsSelected,
      industriesSelected,
      partnershipOpportunitiesSelected,
      activelyHiringsSelected,
      segmentsSelected,
      serviceOfferedsSelected,
    } = get();
    let foundedYear = founded?.fromYear?.toString();

    // able to filter with range year
    if (founded?.toYear) {
      foundedYear = `${foundedYear},${founded?.toYear}`;
    }

    const filterBy: CompanyFilterType = {
      search: searchText,
      founded: foundedYear,
      status,
      isFundraising: getFilterStatusBool(fundraisingStatus),
      isVerified: getFilterStatusBool(verifyStatus),
      regions: regionsSelected.map((item) => item.slug).toString(),
      businessModels: businessModelsSelected
        .map((item) => item.slug)
        .toString(),
      industries: industriesSelected.map((item) => item.slug).toString(),
      partnershipOpportunities: partnershipOpportunitiesSelected
        .map((item) => item.slug)
        .toString(),
      activelyHiring: activelyHiringsSelected
        .map((item) => item.slug)
        .toString(),
      segments: segmentsSelected.map((item) => item.slug).toString(),
      serviceOffereds: serviceOfferedsSelected
        .map((item) => item.slug)
        .toString(),
    };

    set(() => ({
      filterBy,
      currentPage: 0,
    }));
  },
  clearIndustry: () => {
    set(() => ({
      industriesSelected: [],
    }));
  },
  clearBusinessModel: () => {
    set(() => ({
      businessModelsSelected: [],
    }));
  },
  clearRegion: () => {
    set(() => ({
      regionsSelected: [],
    }));
  },
  setCurrentPage: (page: number) => {
    set(() => ({
      currentPage: page,
    }));
  },
  clearPartnershipOpportunities: () => {
    set(() => ({
      partnershipOpportunitiesSelected: [],
    }));
  },
  clearActivelyHiring: () => {
    set(() => ({
      activelyHiringsSelected: [],
    }));
  },
  clearSegments: () => {
    set(() => ({
      segmentsSelected: [],
    }));
  },
  clearServiceOffereds: () => {
    set(() => ({
      serviceOfferedsSelected: [],
    }));
  },
  setFilterData: (filterData: FilterData) => {
    set(() => ({
      filterData,
    }));
  },
  resetAllFilter: () => {
    set(() => ({
      searchText: '',
      regionsSelected: undefined,
      industriesSelected: [],
      businessModelsSelected: [],
      partnershipOpportunitiesSelected: [],
      activelyHiringSelected: [],
      segmentsSelected: [],
      serviceOfferedsSelected: [],
      founded: undefined,
      status: undefined,
      fundraisingStatus: undefined,
      verifyStatus: undefined,
    }));

    get().submitFilter();
  },
  isHasFilterData: () => {
    const { filterBy } = get();
    const {
      search,
      founded,
      isFundraising,
      isVerified,
      regions,
      businessModels,
      industries,
      partnershipOpportunities,
      activelyHiring,
      segments,
      serviceOffereds,
    }: CompanyFilterType = filterBy;

    const isFiltered =
      search.length > 0 ||
      regions.length > 0 ||
      businessModels.length > 0 ||
      industries.length > 0 ||
      partnershipOpportunities.length > 0 ||
      activelyHiring.length > 0 ||
      segments.length > 0 ||
      serviceOffereds.length > 0 ||
      founded !== undefined ||
      isFundraising !== undefined ||
      isVerified !== undefined;

    return isFiltered;
  },
  setFounded: (from?: number, to?: number) => {
    set(() => ({
      founded: {
        fromYear: from,
        toYear: to,
      },
    }));
  },
  setStatus: (status: string) => {
    set(() => ({
      status,
    }));
  },
  setFundraising: (fundraisingStatus: string) => {
    set(() => ({
      fundraisingStatus,
    }));
  },
  setVerify: (verifyStatus: string) => {
    set(() => ({
      verifyStatus,
    }));
  },
}));

export default useFilterStore;
