import create from 'zustand';

import type { IMessage, TMessage } from '@/types/Message.type';

interface IEBookStore {
  email: string;
  loading: boolean;
  message: IMessage;
  openDownloadEBookDialog: boolean;
  setLoading: (loading: boolean) => void;
  setEmail: (email: string) => void;
  setOpenDownloadEBookDialog: (status: boolean) => void;
  setMessage: (name: TMessage, message: string) => void;
  reset: () => void;
}

const iMessage = {
  info: '',
  success: '',
  error: '',
  warning: '',
};

const useEBookStore = create<IEBookStore>((set, get) => ({
  email: '',
  openDownloadEBookDialog: false,
  loading: false,
  message: iMessage,
  setLoading: (loading: boolean) => {
    set(() => ({
      loading,
    }));
  },
  setEmail: (email) => {
    set(() => ({
      email,
      message: iMessage,
    }));
  },
  setOpenDownloadEBookDialog(status) {
    set(() => ({
      openDownloadEBookDialog: status,
    }));
  },
  setMessage: (name: TMessage, message: string) => {
    const state = get();
    set(() => ({
      message: {
        ...state.message,
        [name]: message,
      },
    }));
  },
  reset: () => {
    set(() => ({
      loading: false,
      message: iMessage,
      email: '',
    }));
  },
}));

export default useEBookStore;
