import React from 'react';

import type CommonProps from '@/types/Common.type';
import { classes } from '@/utils/classes';

interface CheckboxProps {
  currentItem: CommonProps;
  selectedItems: CommonProps[];
  handleSelection: () => void;
}

const Checkbox = ({
  currentItem,
  selectedItems,
  handleSelection,
}: CheckboxProps) => {
  const isSelected =
    selectedItems.findIndex((item) => item?.id === currentItem.id) > -1;

  const isSelectedCls = isSelected
    ? 'border-transparent bg-slate-500'
    : 'border-slate-500';

  return (
    <div
      key={currentItem.id}
      className="flex flex-row items-start px-5 py-3 hover:cursor-pointer"
      onClick={handleSelection}
    >
      <div
        className={classes(
          'h-6 w-6 rounded-sm border-2 mr-5',
          isSelectedCls,
          currentItem.description && 'mt-1'
        )}
      />
      <div className="flex-1">
        <h6 className="text-base font-medium">{currentItem.name}</h6>
        <p className="mt-1 font-normal text-slate-500">
          {currentItem.description}
        </p>
      </div>
    </div>
  );
};

export default Checkbox;
