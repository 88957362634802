import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-export-i18n';
import React from 'react';
import {
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
} from 'react-share';

import FacebookIcon from '@/assets/facebook.svg';
import InstagramIcon from '@/assets/instagram-icon.svg';
import LinkedInIcon from '@/assets/linkedin.svg';
import { classes } from '@/utils/classes';

interface IFooter {
  className?: string;
}

const Footer = ({ className }: IFooter) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center">
      <div
        className={classes(
          'flex w-full flex-col items-center justify-between py-3 md:flex-row-reverse md:py-0',
          className
        )}
      >
        <div className="flex flex-row items-center justify-end space-x-3">
          <LinkedinShareButton
            url={'https://www.linkedin.com/company/slash-co/'}
          >
            <Image
              src={LinkedInIcon}
              alt="Slash Linkedin Official Page"
              width={25}
              quality={100}
            />
          </LinkedinShareButton>
          <FacebookShareButton url={'https://www.facebook.com/slashfoundry'}>
            <Image
              src={FacebookIcon}
              alt="Slash Facebook Official Page"
              width={25}
              quality={100}
            />
          </FacebookShareButton>
          <InstapaperShareButton url={'https://www.instagram.com/slash.vb/'}>
            <Image
              src={InstagramIcon}
              alt="Slash Instagram Official Page"
              width={25}
              quality={100}
            />
          </InstapaperShareButton>
        </div>

        <div className="flex flex-1 flex-row items-center">
          <Link href="/about">
            <a className="text-sm hover:underline" aria-label="listitem">
              {t('footer.about')}
            </a>
          </Link>
          {/* <span className="relative top-px mx-2 inline-block h-1 w-1 rounded-full bg-gray-800"></span>
          <Link href="/featured-companies">
            <a className="text-sm hover:underline">
              {t('nav.featuredCompanies')}
            </a>
          </Link> */}
          <span className="relative top-px mx-2 inline-block h-1 w-1 rounded-full bg-gray-800"></span>
          <Link href="/privacy-policy">
            <a className="text-sm hover:underline" aria-label="listitem">
              {t('nav.privacyPolicy')}
            </a>
          </Link>
        </div>
      </div>
      <hr />
      <div className="w-full border-t py-3">
        <div className="text-center text-sm">
          {t('footer.copyright.part_1', { year: new Date().getFullYear() })}
          <span>
            <Link href="https://slash.co/">
              <a
                href="https://slash.co/"
                target="_blank"
                className="text-sm font-medium hover:underline"
                rel="noreferrer"
              >
                {t('footer.copyright.part_2')}
              </a>
            </Link>
          </span>
          {t('footer.copyright.part_3')}
        </div>
      </div>
    </div>
  );
};

export default Footer;
