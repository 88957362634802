import { ArrowDownTrayIcon, Bars3Icon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-export-i18n';

import VBLogo from '@/assets/logo.svg';
import VBMiniLogo from '@/assets/mini-logo.svg';
import MegaSearch from '@/components/megaSearch/MegaSearch';
import useEBookStore from '@/store/EBookStore';
import useModalStore from '@/store/NavbarStore';
import { logCustomEvent } from '@/utils/analytic';
import { AnalyticEvent } from '@/utils/enum';

type NavbarProps = {
  isStickyNav: boolean;
};

const Navbar = ({ isStickyNav }: NavbarProps) => {
  const { t } = useTranslation();
  const { toggleDrawer } = useModalStore((state) => state);
  const { setOpenDownloadEBookDialog } = useEBookStore((state) => state);
  const containerClassName = isStickyNav ? 'sticky' : '';

  return (
    <header
      className={`${containerClassName} top-0 z-50 max-h-20 w-full shadow-sm dark:bg-black`}
    >
      <div className="mx-auto bg-white p-4">
        <div className="flex items-start justify-between">
          <Link href="/">
            <a
              className="hidden h-10 lg:block"
              onClick={() => logCustomEvent(AnalyticEvent.LogoClick)}
            >
              <Image
                src={VBLogo}
                height={55}
                width={180}
                alt="Venture Builder Map Logo"
                quality={100}
              />
            </a>
          </Link>

          <Link href="/">
            <a className="h-10 lg:hidden">
              <Image
                src={VBMiniLogo}
                height={50}
                width={50}
                alt="Venture Builder Map Logo"
                quality={100}
              />
            </a>
          </Link>

          <MegaSearch />

          <div className="hidden items-center justify-end lg:flex">
            {/* <Link href="https://slash.co/resources/vbmap-podcasts/">
              <a
                target="_blank"
                aria-label="listitem"
                className="px-5 py-2 text-sm font-medium"
                onClick={() => logCustomEvent(AnalyticEvent.Podcasts)}
              >
                {t('nav.podcasts')}
              </a>
            </Link> */}
            <Link href="https://docs.google.com/forms/d/e/1FAIpQLSdl6FlQdDdV66H760hcDhxviYFGEAbI27AtcYLWnIKpVaJYoA/viewform?usp=sf_link">
              <a
                target="_blank"
                aria-label="listitem"
                className="px-5 py-2 text-sm font-medium"
                onClick={() => logCustomEvent(AnalyticEvent.RequestNewCompany)}
              >
                {t('nav.addVentureBuilder')}
              </a>
            </Link>

            <button
              onClick={() => {
                setOpenDownloadEBookDialog(true);
                logCustomEvent(AnalyticEvent.DownloadEbook);
              }}
              className="md btn-primary flex h-12 items-center justify-center rounded-full bg-slate-900 px-5"
            >
              <ArrowDownTrayIcon className="mr-1 h-6 w-5" />
              {t('nav.downloadEBook')}
            </button>
          </div>

          <div className="flex items-center justify-center pt-2 lg:hidden">
            <button
              className="flex h-9 w-9 items-center justify-center rounded-lg text-gray-900"
              type="button"
              onClick={() => {
                toggleDrawer(true);
                logCustomEvent(AnalyticEvent.Drawer);
              }}
            >
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="w-6 text-slate-500" />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export { Navbar };
