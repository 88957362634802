import { EnvelopeIcon } from '@heroicons/react/24/outline';
import React from 'react';

import { classes } from '@/utils/classes';

type RoundInputProps = {
  handleSubmit?: (
    event?: React.SyntheticEvent | KeyboardEvent | TouchEvent | MouseEvent
  ) => void;
  submitLabel?: string;
  alignment?: string;
  inputSize?: string;
  placeholder: string;
  loading: boolean;
  error: boolean;
  value: string;
  onChange: React.ReactEventHandler<HTMLInputElement>;
};

const RoundInput = ({
  submitLabel,
  alignment = 'justify-center',
  inputSize = 'w-96 max-w-md',
  placeholder,
  loading,
  error,
  value,
  onChange,
  handleSubmit,
}: RoundInputProps) => {
  return (
    <div className={`flex flex-1 ${alignment}`}>
      <div className={`relative ${inputSize}`}>
        <div className="absolute inset-y-0 left-4 m-auto flex justify-center">
          <EnvelopeIcon className="w-5 text-slate-400" />
        </div>

        <input
          className={classes(
            `h-10 rounded-full bg-slate-100 p-4 pl-12 text-sm`,
            inputSize,
            error && 'border-2 border-red-500'
          )}
          placeholder={placeholder}
          type="text"
          value={value || ''}
          onChange={onChange}
        />

        {handleSubmit && (
          <button
            className="btn-primary absolute inset-y-0 right-0 rounded-full bg-slate-900"
            onClick={handleSubmit}
          >
            {loading ? (
              <svg
                className="mx-5 h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              submitLabel
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default RoundInput;
