import create from 'zustand';

interface ModalState {
  isDrawerVisible: boolean;
  isSubscriptionVisible: boolean;
  isDownloadEBookVisible: boolean;
  isMegaSearchMobileVisible: boolean;
  toggleDrawer: (visible: boolean) => void;
  toggleSubscription: (visible: boolean) => void;
  toggleDownloadEbook: (visible: boolean) => void;
  toggleMegaSearchMobile: (visible: boolean) => void;
}

const useModalStore = create<ModalState>((set) => ({
  isDrawerVisible: false,
  isSubscriptionVisible: false,
  isDownloadEBookVisible: false,
  isMegaSearchMobileVisible: false,
  toggleDrawer: (visible: boolean) => {
    set(() => ({
      isDrawerVisible: visible,
    }));
  },
  toggleSubscription: (visible: boolean) => {
    set(() => ({
      isDrawerVisible: false,
      isSubscriptionVisible: visible,
    }));
  },
  toggleDownloadEbook: (visible: boolean) => {
    set(() => ({
      isDownloadEBookVisible: visible,
    }));
  },
  toggleMegaSearchMobile: (visible: boolean) => {
    set(() => ({
      isMegaSearchMobileVisible: visible,
    }));
  },
}));

export default useModalStore;
