import Link from 'next/link';
import { useTranslation } from 'next-export-i18n';
import type { ReactNode } from 'react';
import CookieConsent from 'react-cookie-consent';

import NewsletterModal from '@/components/modal/newsletter';
import MegaSearchMobileDialog from '@/components/organisms/MegaSearchMobileDialog';
import MenuDrawer from '@/components/templates/Drawer';
import { Navbar } from '@/layouts/Navbar';

type IMainProps = {
  meta: ReactNode;
  children: ReactNode;
  screenSize?: string;
  isStickyNav?: boolean;
};

const Main = ({ meta, screenSize, children, isStickyNav }: IMainProps) => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen w-full text-gray-700 antialiased">
      {meta}
      <div className="flex min-h-screen flex-col">
        <Navbar isStickyNav={isStickyNav || false} />
        <div className={`flex flex-1 flex-col ${screenSize}`}>{children}</div>
        <MenuDrawer />
        <MegaSearchMobileDialog />
        <NewsletterModal />
      </div>

      <CookieConsent
        location="bottom"
        buttonText="CONTINUE"
        cookieName="cookiePolicy"
        containerClasses="bg-slate-900 py-5 fixed bottom-0 z-50 flex flex-col md:flex-row w-full justify-center items-center text-white text-center md:text-left"
        contentClasses="mx-5"
        expires={150}
        buttonClasses="bg-white shadow-sm rounded-full text-slate-900 px-5 py-2 mx-5 hover:bg-gray-300 mt-5 md:mt-0"
        disableButtonStyles
        disableStyles
      >
        <span className="mr-2 ">{t('footer.cookiesDescription')}</span>
        <Link href="/privacy-policy">
          <a className="font-bold underline">{t('footer.leardMore')}</a>
        </Link>
      </CookieConsent>
    </div>
  );
};

export { Main };
