import { Transition } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import debounce from 'lodash.debounce';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-export-i18n';
import React, { useCallback, useEffect } from 'react';
import useSWR from 'swr';

import useFilterStore from '@/store/FilterStore';
import useModalStore from '@/store/NavbarStore';
import type CommonProps from '@/types/Common.type';
import { logCustomEvent } from '@/utils/analytic';
import { AnalyticEvent } from '@/utils/enum';

import LinkButton from '../atoms/LinkButton';
import BusinessModelSelect from './BusinessModelSelect';
import IndustrySelect from './IndustrySelect';
import RegionsSelect from './RegionsSelect';

type FilterByProps = {
  regions: CommonProps[];
  businessModels: CommonProps[];
  industries: CommonProps[];
  partnershipOpportunities: CommonProps[];
  activelyHirings: CommonProps[];
};

const fetcher = (url: string) => fetch(url).then((res) => res.json());
const FILTER_BY_API = `${process.env.NEXT_PUBLIC_API_URL}/common-filter`;

const MegaSearch = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { data } = useSWR(`${FILTER_BY_API}`, fetcher, {
    revalidateOnFocus: false,
    fallbackData: {
      regions: [],
      businessModels: [],
      industries: [],
    },
  });

  const { regions, businessModels, industries }: FilterByProps = data;

  const {
    isVisible,
    toggleFilter,
    regionsSelected,
    businessModelsSelected,
    industriesSelected,
    searchText,
    updateSearchText,
    submitFilter,
    setFilterData,
    filterData,
  } = useFilterStore((state) => state);

  const { toggleMegaSearchMobile } = useModalStore((state) => state);

  const { resetAllFilter, isHasFilterData } = useFilterStore((state) => state);

  const formClass = isVisible
    ? 'h-16 w-full mt-11 top-5'
    : 'h-12 w-96 mt-0 hover:cursor-pointer top-0';
  const formClassMobile = 'h-12 w-full mt-0 hover:cursor-pointer top-0';
  const searchBtnClass = isVisible ? 'h-12 w-12' : 'h-9 w-9';
  const miniMenu = isVisible ? 'opacity-100 top-0' : 'opacity-25 -top-28';
  const containerCls = isVisible ? 'h-12 md:h-32' : 'h-12';
  const searchContainerCls =
    'absolute w-auto max-w-2xl items-center justify-between rounded-full bg-slate-100 pl-6 pr-2 transition-all duration-300';

  const handleInputChange = debounce((e) => {
    updateSearchText(e.target.value);
  }, 1000);

  const handleSubmit = useCallback(() => {
    const path = router.asPath;
    if (path !== '/') {
      router.back();
    }

    submitFilter();

    setTimeout(() => {
      toggleFilter(false);
    }, 250);
  }, [regionsSelected, businessModelsSelected, industriesSelected, searchText]);

  const isEnableCTA = useCallback(() => {
    return (
      regionsSelected ||
      businessModelsSelected.length > 0 ||
      industriesSelected.length > 0 ||
      searchText.length > 0
    );
  }, [regionsSelected, businessModelsSelected, industriesSelected, searchText]);

  const handleReset = useCallback(() => {
    resetAllFilter();
    toggleMegaSearchMobile(false);

    logCustomEvent(AnalyticEvent.ResetAllFilter);
  }, []);

  useEffect(() => {
    if (regions.length > 0 && !filterData) {
      setFilterData(data);
    }
  }, [regions]);

  return (
    <div
      className={`relative flex flex-1 flex-col items-center justify-center transition-all duration-300 ${containerCls}`}
    >
      <ul
        className={`absolute top-3 mb-4 hidden flex-row justify-around md:flex ${miniMenu} transition-all duration-300 ease-out`}
      >
        <li>
          <Link href="/">
            <a
              aria-label="listitem"
              className="flex flex-col px-3 text-sm font-medium"
              onClick={() => toggleFilter(false)}
            >
              <span>{t('nav.explore')}</span>
              <span className="border-gradient m-auto mt-1 h-0.5 w-2/3 rounded-full"></span>
            </a>
          </Link>
        </li>
        <li>
          <Link href="/featured-companies">
            <a
              aria-label="listitem"
              className="flex flex-col px-3 text-sm"
              onClick={() => toggleFilter(false)}
            >
              <span>{t('nav.featuredCompanies')}</span>
              <span className="border border-transparent"></span>
            </a>
          </Link>
        </li>
        <li>
          <button
            className="flex flex-col px-3 text-sm"
            onClick={() => {
              toggleMegaSearchMobile(true);

              logCustomEvent(AnalyticEvent.AdvanceFilter);

              setTimeout(() => {
                toggleFilter(false);
              }, 500);
            }}
          >
            <span>{t('nav.advanceFilter')}</span>
          </button>
          {/* <Link href="#">
            <a
              aria-label="listitem"
              className="flex flex-col px-3 text-sm"
              onClick={() => toggleFilter(false)}
            >
              <span>{t('nav.advanceFilter')}</span>
              <span className="border border-transparent"></span>
            </a>
          </Link> */}
        </li>
      </ul>

      <div
        className={`hidden ${searchContainerCls} hover:shadow-sm md:flex ${formClass}`}
        onClick={() => {
          toggleFilter(true);
          logCustomEvent(AnalyticEvent.BasicFilter, {
            action: 'open',
          });
        }}
      >
        <Transition
          appear
          unmount
          show={isVisible}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="flex w-28 flex-col">
            <h6 className="text-sm font-medium">Search</h6>
            <input
              defaultValue={searchText}
              className={`w-full bg-transparent text-sm outline-none`}
              placeholder="Type anything"
              type="text"
              onChange={handleInputChange}
            />
          </div>
        </Transition>

        {isVisible && <span className="mx-3 h-5 w-px bg-slate-200"></span>}
        <RegionsSelect data={regions} isFilterExpended={isVisible} />

        <span className="mx-3 h-5 w-px bg-slate-200"></span>
        <BusinessModelSelect
          data={businessModels}
          isFilterExpended={isVisible}
        />

        <span className="mx-3 h-5 w-px bg-slate-200"></span>
        <IndustrySelect data={industries} isFilterExpended={isVisible} />

        <button
          className={`ml-3 flex items-center justify-center rounded-full ${
            isEnableCTA() ? 'border-gradient' : 'bg-slate-400'
          } ${searchBtnClass} transition-all duration-300`}
          onClick={handleSubmit}
          name="Submit Filter"
        >
          <MagnifyingGlassIcon className="w-5 text-white" />
        </button>

        {isVisible && isHasFilterData() && (
          <div className="absolute -right-16 text-sm">
            <LinkButton title="Reset" onClick={handleReset} />
          </div>
        )}
      </div>

      <div className="flex items-center justify-center md:hidden">
        <div
          className={`flex flex-row items-center justify-center rounded-full bg-slate-100 pl-4 pr-2 hover:shadow-sm ${formClassMobile}`}
          onClick={() => {
            toggleMegaSearchMobile(true);

            logCustomEvent(AnalyticEvent.BasicFilter, {
              action: 'open',
              type: 'mobile',
            });
          }}
        >
          <div>
            <h6 className="text-sm">Search venture here</h6>
          </div>

          <button
            className={`ml-3 flex h-9 w-9 items-center justify-center rounded-full ${
              isEnableCTA() ? 'border-gradient' : 'bg-slate-400'
            }`}
            onClick={handleSubmit}
            name="Submit Filter"
          >
            <MagnifyingGlassIcon className="w-5 text-white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MegaSearch;
