import { Transition } from '@headlessui/react';
import { useTranslation } from 'next-export-i18n';
import React from 'react';

import useModalStore from '@/store/NavbarStore';
import type MenuLinkProps from '@/types/MenuLink.type';
import { AnalyticEvent } from '@/utils/enum';

import Overlay from '../atoms/Overlay';
import VerticalMenu from '../organisms/VerticalMenu';

const MenuDrawer = () => {
  const { isDrawerVisible, toggleDrawer } = useModalStore((state) => state);

  const { t } = useTranslation();
  const menuLinks: MenuLinkProps[] = [
    // {
    //   link: 'https://slash.co/resources/vbmap-podcasts/',
    //   label: t('nav.podcasts'),
    //   logEventName: AnalyticEvent.Podcasts,
    // },
    {
      link: 'https://docs.google.com/forms/d/e/1FAIpQLSdl6FlQdDdV66H760hcDhxviYFGEAbI27AtcYLWnIKpVaJYoA/viewform?usp=sf_link',
      label: t('nav.addVentureBuilder'),
      logEventName: AnalyticEvent.RequestNewCompany,
    },
  ];

  return (
    <Transition
      show={isDrawerVisible}
      unmount
      className="fixed inset-0 z-50"
      appear
    >
      {/* Background overlay */}
      <Transition.Child
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="z-50"
      >
        <Overlay onClick={() => toggleDrawer(false)} />
      </Transition.Child>

      {/* Sliding sidebar */}
      <Transition.Child
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        className="right-0 z-50 h-full w-80"
      >
        <VerticalMenu menus={menuLinks} />
      </Transition.Child>
    </Transition>
  );
};

export default MenuDrawer;
