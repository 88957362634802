import create from 'zustand';

import type { IMessage, TMessage } from '@/types/Message.type';

interface IOtherStore {
  loading: boolean;
  message: IMessage;
  email: string;
  setEmail: (email: string) => void;
  setLoading: (loading: boolean) => void;
  setMessage: (name: TMessage, message: string) => void;
  reset: () => void;
}

const iMessage = {
  info: '',
  success: '',
  error: '',
  warning: '',
};

const useNewsletterStore = create<IOtherStore>((set, get) => ({
  loading: false,
  message: iMessage,
  email: '',
  setLoading: (loading: boolean) => {
    set(() => ({
      loading,
    }));
  },
  setEmail: (email) => {
    set(() => ({
      email,
      message: iMessage,
    }));
  },
  setMessage: (name: TMessage, message: string) => {
    const state = get();
    set(() => ({
      message: {
        ...state.message,
        [name]: message,
      },
    }));
  },
  reset: () => {
    set(() => ({
      loading: false,
      message: iMessage,
      email: '',
    }));
  },
}));

export default useNewsletterStore;
