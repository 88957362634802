import React from 'react';

import { classes } from '@/utils/classes';

interface OutlineButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  label: string;
  size?: 'sm' | 'md' | 'lg';
  onClick: () => void;
}

const sizes = {
  sm: 'py-1.5 px-4 text-xs',
  md: 'py-3 px-6 text-sm',
  lg: 'py-4 px-8 text-base',
};

const OutlineButton = ({
  label,
  size = 'md',
  onClick,
  ...restProps
}: OutlineButtonProps) => {
  return (
    <button
      className={classes(
        'flex items-center justify-center rounded-full border border-slate-900 uppercase font-medium text-slate-900',
        'hover:bg-slate-100',
        sizes[size]
      )}
      onClick={onClick}
      {...restProps}
    >
      {label}
    </button>
  );
};

export default OutlineButton;
