import Link from 'next/link';
import React from 'react';

import type MenuLinkProps from '@/types/MenuLink.type';
import { logCustomEvent } from '@/utils/analytic';

const MenuLink = ({ label, link, logEventName }: MenuLinkProps) => {
  return (
    <Link href={link}>
      <a
        aria-label="listitem"
        className="px-6 py-3 text-sm font-medium"
        onClick={() => {
          if (logEventName) logCustomEvent(logEventName);
        }}
      >
        {label}
      </a>
    </Link>
  );
};

export default MenuLink;
