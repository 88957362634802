import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'next-export-i18n';
import React, { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { Subscribe } from '@/lib/api/Subscribe.api';
import useNewsletterStore from '@/store/NewsletterStore';

import RoundInput from '../forms/RoundInput';

export default function NewsletterSubscriptionForm() {
  const { loading, message, email, setEmail, setMessage, setLoading } =
    useNewsletterStore((state) => state);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { t } = useTranslation();

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const handleSubscribe = useCallback(
    async (gReCaptchaToken: string) => {
      if (!email) {
        return;
      }

      setLoading(true);
      const res = await Subscribe(email, gReCaptchaToken);

      const { error, message: messageResp } = await res.json();

      if (error) {
        if (messageResp && messageResp === 'Member Exists') {
          // 4. If there was an error, update the message in state.
          setLoading(false);
          setMessage('info', 'newsletter.message.info');

          return;
        }
        setLoading(false);
        setMessage('error', error);

        return;
      }
      setLoading(false);
      setEmail('');
      setMessage('success', 'newsletter.message.success');
    },
    [email]
  );

  const handleSumitForm = useCallback(
    (e: any) => {
      e.preventDefault();
      if (!executeRecaptcha) {
        return;
      }
      executeRecaptcha('enquiryFormSubmit').then((gReCaptchaToken) => {
        handleSubscribe(gReCaptchaToken);
      });
    },
    [executeRecaptcha, email]
  );

  const newsletterBenefit = [
    t('newsletter.benefits.one'),
    t('newsletter.benefits.two'),
    t('newsletter.benefits.three'),
  ];

  return (
    <div className="w-64 flex-1 p-6">
      <h3 className="text-3xl">{t('newsletter.title')}</h3>
      <h6 className="text-base text-gray-500">{t('newsletter.subTitle')}</h6>

      <h6 className="mt-8 text-base">{t('newsletter.benefits.title')}</h6>
      <ul>
        {newsletterBenefit.map((item, index) => {
          return (
            <li
              key={index}
              className="my-3 flex flex-row items-start justify-start"
            >
              <CheckCircleIcon className="w-6 text-green-400" />
              <h6 className="ml-3 w-64 flex-1 text-base">{item}</h6>
            </li>
          );
        })}
      </ul>

      <div className="mt-8">
        <RoundInput
          submitLabel={t('newsletter.submitCta')}
          placeholder={t('newsletter.subscribeInputPlaceholder')}
          handleSubmit={handleSumitForm}
          alignment="initial"
          inputSize="w-full"
          loading={loading}
          error={!!message.error || !!message.info}
          value={email}
          onChange={handleInputChange}
        />
      </div>

      {message.error && (
        <h6 className="mt-2 text-sm text-red-500">
          <i>{t(message.error)}</i>
        </h6>
      )}
      {message.info && (
        <h6 className="mt-2 text-sm">
          <i>{t(message.info)}</i>
        </h6>
      )}
      {!message.error && !message.info && (
        <h6 className="mt-2 text-sm text-gray-500">
          <i>{t('newsletter.noSpamMessage')}</i>
        </h6>
      )}
    </div>
  );
}
