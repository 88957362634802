import React from 'react';

import type CommonProps from '@/types/Common.type';

import Checkbox from '../atoms/Checkbox';
import Expandable from '../templates/Expandable';

interface IMultipleChoiceFilter {
  title: string;
  selectedValues: CommonProps[];
  items: CommonProps[];

  handleClear: () => void;
  handleSelection: (item: CommonProps) => void;
}

const MultipleChoiceFilter = ({
  title,
  selectedValues,
  items,
  handleClear,
  handleSelection,
}: IMultipleChoiceFilter) => {
  return (
    <div className="mt-5">
      <Expandable
        title={title}
        valueSelected={selectedValues.map((item) => item.name).join(', ')}
        handleClear={handleClear}
      >
        <div className="grid max-w-full grid-cols-1 gap-0 overflow-x-auto md:grid-cols-2">
          {items.map((item) => (
            <Checkbox
              key={item.id}
              currentItem={item}
              selectedItems={selectedValues}
              handleSelection={() => handleSelection(item)}
            />
          ))}
        </div>
      </Expandable>
    </div>
  );
};

export default MultipleChoiceFilter;
