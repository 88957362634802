import React from 'react';

type OverlayProps = {
  onClick: () => void;
};

const Overlay = ({ onClick }: OverlayProps) => {
  return (
    <div className="fixed inset-0 bg-black opacity-30" onClick={onClick} />
  );
};

export default Overlay;
