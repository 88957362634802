import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-export-i18n';
import React from 'react';

import VBLogo from '@/assets/logo.svg';
import useEBookStore from '@/store/EBookStore';
import useModalStore from '@/store/NavbarStore';
import type VerticalMenuProps from '@/types/Menu.type';
import { logCustomEvent } from '@/utils/analytic';
import { AnalyticEvent } from '@/utils/enum';

import CloseModalButton from '../atoms/CloseModalButton';
import MenuLink from '../molecules/MenuLink';

const VerticalMenu = ({ menus }: VerticalMenuProps) => {
  const { toggleSubscription } = useModalStore((state) => state);
  const { setOpenDownloadEBookDialog } = useEBookStore((state) => state);
  const { t } = useTranslation();

  return (
    <div className="h-full bg-white pb-5 pt-2">
      <div className="flex flex-col">
        <div className="flex flex-row items-center justify-between px-3 pb-3">
          <Link href="/">
            <a
              className="h-10"
              onClick={() => logCustomEvent(AnalyticEvent.LogoClick)}
            >
              <Image
                src={VBLogo}
                height={55}
                width={180}
                alt="Venture Builder Map Logo"
                quality={100}
              />
            </a>
          </Link>

          <CloseModalButton handleClick={() => toggleSubscription(false)} />
        </div>

        {menus.map((menu) => (
          <MenuLink
            key={menu.label.toLowerCase()}
            link={menu.link}
            label={menu.label}
            logEventName={menu.logEventName}
          />
        ))}

        <div className="py-3 px-6">
          <button
            onClick={() => {
              setOpenDownloadEBookDialog(true);
              logCustomEvent(AnalyticEvent.DownloadEbook);
            }}
            className="btn-primary flex h-11 items-center justify-center bg-slate-900 px-5"
          >
            <ArrowDownTrayIcon className="mr-1 h-6 w-5" />
            {t('nav.downloadEBook')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerticalMenu;
