export const Subscribe = async (email: string, gReCaptchaToken: string) => {
  const res = await fetch('/api/subscribe', {
    body: JSON.stringify({
      email,
      gReCaptchaToken,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  return res;
};
