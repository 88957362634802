import React from 'react';

interface ListTileProps {
  title: string;
  subTitle: string;
}

const ListTile = ({ title, subTitle }: ListTileProps) => {
  return (
    <div className="flex flex-col">
      <h2 className="font-medium">{title}</h2>
      <h6 className="mt-1 text-slate-500">{subTitle}</h6>
    </div>
  );
};

export default ListTile;
