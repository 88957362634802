import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-export-i18n';
import React, { useCallback, useState } from 'react';

import useFilterStore from '@/store/FilterStore';
import useModalStore from '@/store/NavbarStore';
import { logCustomEvent } from '@/utils/analytic';
import { classes } from '@/utils/classes';
import { FILTER_BTN_GROUP } from '@/utils/const';
import { AnalyticEvent } from '@/utils/enum';
import { getYearsOptions } from '@/utils/helper';

import Button from '../atoms/Button';
import CloseModalButton from '../atoms/CloseModalButton';
import SimpleInput from '../atoms/Input';
import LinkButton from '../atoms/LinkButton';
import ListTile from '../atoms/ListTile';
import OutlineButton from '../atoms/OutlineButton';
import RegionCard from '../atoms/RegionCard';
import YearSelection from '../atoms/YearsSelection';
import Expandable from '../templates/Expandable';
import SimplePopup from '../templates/Popup';
import ButtonGroupFilter from './ButtonGroupFilter';
import MultipleChoiceFilter from './MultipleChoiceFilter';

const MegaSearchMobileDialog = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { isMegaSearchMobileVisible, toggleMegaSearchMobile } = useModalStore(
    (state) => state
  );
  const {
    status,
    founded,
    verifyStatus,
    fundraisingStatus,
    regionsSelected,
    businessModelsSelected,
    industriesSelected,
    partnershipOpportunitiesSelected,
    activelyHiringsSelected,
    segmentsSelected,
    serviceOfferedsSelected,
    filterData,
    setRegions,
    setBusinessModel,
    setIndustry,
    setPartnershipOpportunities,
    setActivelyHirings,
    setFundraising,
    setFounded,
    setVerify,
    setSegments,
    setServiceOffereds,
    updateSearchText,
    clearBusinessModel,
    clearIndustry,
    clearRegion,
    clearPartnershipOpportunities,
    clearActivelyHiring,
    clearSegments,
    clearServiceOffereds,
    submitFilter,
    searchText,
    resetAllFilter,
    setStatus,
    isHasFilterData,
  } = useFilterStore((state) => state);

  const [isRangeYearFilter, setRangeYearFilter] = useState(false);
  const years = getYearsOptions();

  const handleSubmit = useCallback(() => {
    const path = router.asPath;
    if (path !== '/') {
      router.back();
    }

    submitFilter();
    toggleMegaSearchMobile(false);
  }, [
    regionsSelected,
    businessModelsSelected,
    industriesSelected,
    partnershipOpportunitiesSelected,
    activelyHiringsSelected,
    searchText,
  ]);

  const handleInputChange = debounce((e) => {
    updateSearchText(e.target.value);
  }, 1000);

  const handleReset = useCallback(() => {
    resetAllFilter();
    toggleMegaSearchMobile(false);

    logCustomEvent(AnalyticEvent.ResetAllFilter);
  }, []);
  const getRegionValue = () =>
    regionsSelected.map((item) => item.name).join(', ');

  return (
    <SimplePopup
      isVisible={isMegaSearchMobileVisible}
      handleClose={() => toggleMegaSearchMobile(false)}
      isShowClose={false}
      isFullScreen
    >
      <div className="flex h-full w-full flex-col justify-between self-center">
        <div className="flex flex-row items-center justify-between bg-slate-900 px-2 pl-5 md:mx-5 md:rounded-b-lg">
          <h1 className=" text-lg text-white">
            {t('filter.findVentureBuilder')}
          </h1>

          <CloseModalButton
            handleClick={() => toggleMegaSearchMobile(false)}
            iconClass="text-white"
          />
        </div>

        <div className="flex-1 overflow-y-auto bg-slate-50 py-5">
          <div className="px-5">
            <SimpleInput
              handleChange={handleInputChange}
              placeholder={t('filter.searchPlaceHolder')}
              className="border-b pb-4 text-base"
              label={t('filter.searchLabel')}
            />
          </div>

          <div className="mt-5 px-5 md:mt-8">
            <div className="flex flex-col justify-between md:flex-row">
              <ListTile
                title={t('filter.founded.title')}
                subTitle={t('filter.founded.description')}
              />

              <div className="relative mt-2 flex flex-col items-start md:mt-0 md:flex-row">
                <div className="flex flex-row items-center">
                  <div
                    className={classes(
                      !isRangeYearFilter && 'w-36',
                      isRangeYearFilter && 'w-24'
                    )}
                  >
                    <YearSelection
                      placeholder={
                        isRangeYearFilter ? t('filter.founded.from') : undefined
                      }
                      items={years}
                      handleChange={(e) => {
                        setFounded(e.id, founded?.toYear);
                      }}
                      max={isRangeYearFilter ? founded?.toYear : undefined}
                    />
                  </div>
                  {isRangeYearFilter && (
                    <span className="relative top-1 mx-3"> - </span>
                  )}
                  {isRangeYearFilter && (
                    <div
                      className={classes(
                        !isRangeYearFilter && 'w-36',
                        isRangeYearFilter && 'w-24'
                      )}
                    >
                      <YearSelection
                        placeholder={
                          isRangeYearFilter ? t('filter.founded.to') : undefined
                        }
                        items={years}
                        handleChange={(e) => {
                          setFounded(founded?.fromYear, e.id);
                        }}
                        min={isRangeYearFilter ? founded?.fromYear : undefined}
                      />
                    </div>
                  )}
                </div>

                <div className="relative mt-2 origin-left scale-75 md:absolute md:right-0 md:-top-5 md:mt-0 md:origin-right">
                  <LinkButton
                    title={
                      isRangeYearFilter ? 'Specific Year' : 'Range Selection'
                    }
                    onClick={() => {
                      setRangeYearFilter(!isRangeYearFilter);

                      logCustomEvent(AnalyticEvent.FoundedYearFilterType, {
                        action: !isRangeYearFilter
                          ? 'Specific Year'
                          : 'Range Selection',
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-5 px-5 md:mt-8">
            <ButtonGroupFilter
              title={t('filter.status.title')}
              subTitle={t('filter.status.description')}
              value={status ?? ''}
              buttons={FILTER_BTN_GROUP.status}
              onChange={(e) => {
                setStatus(e);
              }}
            />
          </div>

          <div className="mt-5 px-5 md:mt-8">
            <ButtonGroupFilter
              title={t('filter.fundraising.title')}
              subTitle={t('filter.fundraising.description')}
              value={fundraisingStatus ?? ''}
              buttons={FILTER_BTN_GROUP.fundraising}
              onChange={(e) => {
                setFundraising(e);
              }}
            />
          </div>

          <div className="mt-5 px-5 md:mt-8">
            <ButtonGroupFilter
              title={t('filter.verify.title')}
              subTitle={t('filter.verify.description')}
              value={verifyStatus ?? ''}
              buttons={FILTER_BTN_GROUP.verify}
              onChange={(e) => {
                setVerify(e);
              }}
            />
          </div>

          <div className="mt-5 md:mt-10">
            <Expandable
              title={t('filter.regions')}
              valueSelected={getRegionValue()}
              handleClear={() => clearRegion()}
            >
              <div className="grid max-w-full grid-flow-col grid-rows-1 gap-4 overflow-x-auto px-5">
                {filterData?.regions.map((item) => (
                  <div key={item.id} className="w-24">
                    <RegionCard
                      region={item}
                      selected={regionsSelected}
                      handleSelection={() => setRegions(item)}
                    />
                  </div>
                ))}
              </div>
            </Expandable>
          </div>

          <MultipleChoiceFilter
            title={t('filter.businessModels')}
            selectedValues={businessModelsSelected}
            items={filterData?.businessModels ?? []}
            handleClear={() => clearBusinessModel()}
            handleSelection={setBusinessModel}
          />

          <MultipleChoiceFilter
            title={t('filter.segments')}
            selectedValues={segmentsSelected}
            items={filterData?.segments ?? []}
            handleClear={() => clearSegments()}
            handleSelection={setSegments}
          />

          <MultipleChoiceFilter
            title={t('filter.industries')}
            selectedValues={industriesSelected}
            items={filterData?.industries ?? []}
            handleClear={() => clearIndustry()}
            handleSelection={setIndustry}
          />

          <MultipleChoiceFilter
            title={t('filter.partnershipOpportunities')}
            selectedValues={partnershipOpportunitiesSelected}
            items={filterData?.partnershipOpportunities ?? []}
            handleClear={() => clearPartnershipOpportunities()}
            handleSelection={setPartnershipOpportunities}
          />

          <MultipleChoiceFilter
            title={t('filter.activelyHiring')}
            selectedValues={activelyHiringsSelected}
            items={filterData?.activelyHirings ?? []}
            handleClear={() => clearActivelyHiring()}
            handleSelection={setActivelyHirings}
          />

          <MultipleChoiceFilter
            title={t('filter.servicesOffers')}
            selectedValues={serviceOfferedsSelected}
            items={filterData?.serviceOffereds ?? []}
            handleClear={() => clearServiceOffereds()}
            handleSelection={setServiceOffereds}
          />
        </div>

        <div className="grid grid-cols-2 gap-5 p-5">
          <OutlineButton
            label={t('filter.resetAll')}
            onClick={handleReset}
            disabled={!isHasFilterData()}
          />
          <Button title={t('filter.submit')} onClick={handleSubmit} />
        </div>
      </div>
    </SimplePopup>
  );
};

export default MegaSearchMobileDialog;
