import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';

import { classes } from '@/utils/classes';

type CloseModalButtonProps = {
  handleClick: () => void;
  iconClass?: string;
};

const CloseModalButton = ({
  handleClick,
  iconClass = 'text-slate-900',
}: CloseModalButtonProps) => {
  return (
    <button className="p-3 text-2xl text-gray-500" onClick={handleClick}>
      <XMarkIcon className={classes('h-6 w-5', iconClass)} />
    </button>
  );
};

export default CloseModalButton;
