import React from 'react';

type InputProps = {
  handleChange: React.ReactEventHandler<HTMLInputElement>;
  label?: string;
  placeholder: string;
  className?: string;
};

const SimpleInput = ({
  handleChange,
  label,
  placeholder,
  className = '',
}: InputProps) => {
  return (
    <div className="flex flex-col">
      {label && <h6 className="mb-3">{label}</h6>}
      <input
        className={`w-full bg-transparent text-sm outline-none ${className}`}
        placeholder={placeholder}
        aria-placeholder={placeholder}
        type="text"
        onChange={handleChange}
      />
    </div>
  );
};

export default SimpleInput;
