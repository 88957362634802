import React from 'react';

import type ButtonGroupItemProps from '@/types/ButtonGroupItem.type';

import ButtonGroup from '../atoms/ButtonGroup';
import ListTile from '../atoms/ListTile';

interface ButtonGroupFilterProps {
  title: string;
  subTitle: string;
  value: string;
  buttons: ButtonGroupItemProps[];
  onChange: (value: string) => void;
}

const ButtonGroupFilter = ({
  title,
  subTitle,
  value,
  buttons,
  onChange,
}: ButtonGroupFilterProps) => {
  return (
    <div className="flex flex-col justify-between md:flex-row">
      <ListTile title={title} subTitle={subTitle} />
      <ButtonGroup value={value} buttons={buttons} onChange={onChange} />
    </div>
  );
};

export default ButtonGroupFilter;
