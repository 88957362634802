import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'next-export-i18n';
import { Fragment, useState } from 'react';

import type AutocomplelteItemProps from '@/types/AutocompleteItem.type';
import { classes } from '@/utils/classes';

interface IYearSelection {
  handleChange: (item: AutocomplelteItemProps) => void;
  items: AutocomplelteItemProps[];
  placeholder?: string;
  min?: number;
  max?: number;
}

const YearSelection = ({
  handleChange,
  items,
  placeholder,
  min = 0,
  max = 0,
}: IYearSelection) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<AutocomplelteItemProps>();
  const [query, setQuery] = useState('');

  const filteredPeople =
    query === ''
      ? items
      : items.filter((item) =>
          item.title
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  return (
    <Combobox
      value={selectedItem}
      onChange={(item) => {
        setSelectedItem(item);
        handleChange(item);
      }}
    >
      <div className="relative mt-1">
        <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md sm:text-sm">
          <Combobox.Input
            className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
            displayValue={(item: AutocomplelteItemProps) =>
              item ? item.title : ''
            }
            placeholder={placeholder ?? t('filter.founded.foundedYear')}
            onChange={(event) => setQuery(event.target.value)}
          />

          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md  bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm">
            {filteredPeople.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredPeople.map((item) => {
                let isDisabled = false;
                if (min || max) {
                  if (min) {
                    isDisabled = item.id < min;
                  }

                  if (max) {
                    isDisabled = item.id > max;
                  }
                }

                return (
                  <Combobox.Option
                    key={item.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-slate-900 text-white' : 'text-gray-900'
                      }`
                    }
                    value={item}
                    disabled={isDisabled}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classes(
                            'block truncate',
                            selected ? 'font-medium' : 'font-normal',
                            isDisabled && 'text-slate-300'
                          )}
                        >
                          {item.title}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-slate-900'
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                );
              })
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
};

export default YearSelection;
