import React from 'react';

import useFilterStore from '@/store/FilterStore';
import type CommonProps from '@/types/Common.type';

import Checkbox from '../atoms/Checkbox';
import CustomPopover from '../popover/CustomPopover';

type BusinessModelSelectProps = {
  isFilterExpended: boolean;
  data: CommonProps[];
};

const BusinessModelSelect = ({
  isFilterExpended,
  data: businessModels,
}: BusinessModelSelectProps) => {
  const { businessModelsSelected, setBusinessModel, clearBusinessModel } =
    useFilterStore((state) => state);

  const getValue = () =>
    businessModelsSelected.map((item) => item.name).join(', ');

  return (
    <div className="flex-1 text-sm">
      <CustomPopover
        title="Business Model"
        placeholder="Any business models"
        isFilterExpended={isFilterExpended}
        value={getValue()}
        onClear={clearBusinessModel}
      >
        <div className="py-2">
          <div className="grid grid-cols-1 gap-0">
            {businessModels.map((item) => (
              <Checkbox
                key={item.id}
                currentItem={item}
                selectedItems={businessModelsSelected}
                handleSelection={() => setBusinessModel(item)}
              />
            ))}
          </div>
        </div>
      </CustomPopover>
    </div>
  );
};

export default BusinessModelSelect;
